import React from "react"
import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function Home() {
  const { languages, originalPath } = useI18next() // Get language info
  const { t, i18n } = useTranslation() // Get translation info

  const siteUrl = "https://arcanode.io/home/"

  return (
    <>
      <GatsbySeo
        title={t("home.seo.title", "Arcanode")}
        description={t(
          "home.seo.description",
          "Arcanode provides expertise and innovation for retail, wholesalers, and IT companies; it’s the secure and high-performing solution that empowers businesses without compromising on cost and quality."
        )}
        canonical={`${siteUrl}/${i18n.language}${originalPath}`}
        openGraph={{
          url: `${siteUrl}/${i18n.language}${originalPath}`,
          title: t("home.seo.title", "Arcanode"),
          description: t(
            "home.seo.description",
            "Discover Arcanode’s innovative solutions for retail, wholesalers, and IT companies. We provide secure, high-performance Web3 and IT solutions to empower your business."
          ),
          images: [
            {
              url: `${siteUrl}/icons/icon-512x512.png`,
              width: 512,
              height: 512,
              alt: "Arcanode",
            },
          ],
          site_name: "Arcanode",
        }}
        twitter={{
          handle: "@arcanode",
          site: "@arcanode",
          cardType: "summary_large_image",
        }}
        languageAlternates={languages.map(lang => ({
          hrefLang: lang,
          href: `${siteUrl}/${lang}${originalPath}`,
        }))}
      />

      <div className="flex flex-col items-center justify-center text-center h-full">
        <p className="text-xs sm:text-sm uppercase max-w-md mb-8">
          <Trans i18nKey="home.header">
            Arcanode is an <strong>Italian Web3 company</strong> developing
            real-time 3D applications, offering highly customizable and
            innovative solutions for individuals and businesses.
          </Trans>
        </p>
        <Link
          to="/contact"
          className="border hover:bg-gray-200 hover:text-gray-700 duration-500 text-white font-bold uppercase text-xs py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          <Trans i18nKey="home.button">Request a Demo</Trans>
        </Link>
      </div>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
